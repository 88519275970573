import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
const HomeGallery = () => {
  const options = {
    dots:true,
    nav:false,
    responsiveClass: true,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 2,
        },
        700: {
            items:3,
        },
        1000: {
            items:4,

        },
        1200: {
            items:4,

        }
    },
};
  return (
     <> 
     <div className="row">
     <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
     <div className="item">
         <div className="project-item">
             <div className="project-thumb gal-big">
                <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-1.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
             </div>
          </div>
      </div>
      <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-2.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
        <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-big">
                <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-3.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
     <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-4.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
        <div className="item">
         <div className="project-item">
             <div className="project-thumb gal-big">
                <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-1.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
             </div>
          </div>
      </div>
      <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-2.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
        <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-big">
                <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-3.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
     <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/img-4.jpg" alt="Delhi Police Public School Kingsway,Camp" className="img-fluid"/></Link> 
                </div>
            </div>
        </div>
        </OwlCarousel> 
     </div>
     </>
  )
}

export default HomeGallery