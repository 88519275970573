import React from 'react'
import OwlCarousel from 'react-owl-carousel';
const HomeSlider = () => {
    const options = {
        dots:false,
        items:1,
        nav:false,
    };
  return (
     <>
     <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
        <div className="item">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/DPPSK/public/Images/slide.jpg" atl="Delhi Police Public School,Kingsway Camp, Delhi" class="img-fluid"/>
        </div>
     </OwlCarousel> 
     </>
  )
}

export default HomeSlider
